import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/layouts/blogPost.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Uniswap V2, the second iteration of the Uniswap protocol, has been `}<a parentName="p" {...{
        "href": "https://etherscan.io/address/0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f#code"
      }}>{`deployed to the Ethereum mainnet`}</a>{`!`}</p>
    <p>{`An `}<a parentName="p" {...{
        "href": "https://uniswap.org/audit.html"
      }}>{`audit report and formal verification`}</a>{` has already been released and the `}<a parentName="p" {...{
        "href": "https://twitter.com/Uniswap/status/1250474233131495424"
      }}>{`Uniswap V2 Bug Bounty`}</a>{` has been running for over a month.`}</p>
    <p>{`Developers can begin building on Uniswap V2 immediately! Initial `}<a parentName="p" {...{
        "href": "http://uniswap.org/docs/v2"
      }}>{`docs`}</a>{` and `}<a parentName="p" {...{
        "href": "http://github.com/Uniswap/uniswap-v2-periphery/tree/master/contracts/examples"
      }}>{`example projects`}</a>{` are already available.`}</p>
    <p>{`Today, the following open source projects are also being released:`}</p>
    <ul>
      <li parentName="ul">{`A `}<a parentName="li" {...{
          "href": "https://migrate.app.uniswap.org/"
        }}>{`migration portal`}</a>{` for moving liquidity from Uniswap V1 to Uniswap V2`}</li>
      <li parentName="ul">{`An updated `}<a parentName="li" {...{
          "href": "https://app.uniswap.org/"
        }}>{`interface`}</a>{` for swapping and liquidity provision on Uniswap V2`}</li>
      <li parentName="ul">{`An updated `}<a parentName="li" {...{
          "href": "https://uniswap.info/"
        }}>{`info site`}</a>{` for Uniswap V2 analytics`}</li>
    </ul>
    <p>{`Uniswap V2 has many new features and technical improvements compared with Uniswap V1 including:`}</p>
    <ul>
      <li parentName="ul">{`ERC20 / ERC20 Pairs`}</li>
      <li parentName="ul">{`Price Oracles`}</li>
      <li parentName="ul">{`Flash Swaps`}</li>
      <li parentName="ul">{`And much more!`}</li>
    </ul>
    <p>{`For full details on the benefits of Uniswap V2 for liquidity providers and traders, please read the Uniswap V2 `}<a parentName="p" {...{
        "href": "https://uniswap.org/blog/uniswap-v2"
      }}>{`announcement blog post`}</a>{`. For more information on the launch please read below.`}</p>
    <h2 {...{
      "id": "liquidity-migration",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#liquidity-migration",
        "aria-label": "liquidity migration permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Liquidity Migration`}</h2>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://migrate.app.uniswap.org/"
      }}>{`migration portal`}</a>{` makes the process of withdrawing liquidity from Uniswap V1 and depositing it into Uniswap V2 fast and simple.`}</p>
    <p>{`This portal is only for Uniswap V1 liquidity providers. If you are not a Uniswap V1 liquidity provider but wish to use Uniswap V2, you can do so from the `}<a parentName="p" {...{
        "href": "https://app.uniswap.org/"
      }}>{`updated interface`}</a>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/e55833300837409f3f56c3748c08069b/f349c/migrate.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "78.66666666666667%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAAAsSAAALEgHS3X78AAACGElEQVQ4y31TWZabMBCckzhmlYTYJHawsWEcO8lR8pO75YKVlmCwX8bjj3rdEqhU3dV6c30Bx8DjsLlHcJldL/tr/rFe4a7Yzq3rt8eFIQpkhaiYwfMDhDpBlu82hnHzcCmHF8Twwvg14d4NwdIeSfsLsrogrm+EK61/2rUsJsJMl8wr2ap0Vf9EYWiVRFbVuEWRH8HVBF7eCFdw/Q6PpZu6rwmpX4HQREDlZgN41tsoFBHmI5GeKFKeHcCSduv555K9u0IvqhHkM2FakJ3hiRK+UPayINI2+lx9XfJGSh9YlIPHBQSBS005KZY5vNU0A/OfH0oyRt7L/ky4fNBli6ru0fUjSop1M6A/TGAiJdOYVRKwGHFa2r2nhI/jYKIfRHR7tKpY8pAnFi7ldiapPf+f+1yyb0oxB9MNAUvuIMKAxsU47DL1umRDtncFSp2jH844jDcMxwuVPqMbLmjbA7WigRQJsh9/0Pz+i1Bf4TgenY2eExqVSaqRqxq5bpGpxkKXPaJYLWWT2yzp6DVNq9PstSmGQBUNKjLDGFRUHVoyyBix2/tweQFXVHACTf3MX5Vsnh5HnORIs4pGRZMqGh+pwCNFrtJeYkYpAw9T8LpEkJLL7gvCncMwHgec5yupMr2bUbcnNN2ElpCWHSS1Q9Kgx99HsKaEs2fPx+ZDuiHd7UM7c9+cJdrcDjW3Q23PEJHjPLzldf8f/EvdKIyptokAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "migrate",
            "title": "migrate",
            "src": "/static/e55833300837409f3f56c3748c08069b/c1b63/migrate.png",
            "srcSet": ["/static/e55833300837409f3f56c3748c08069b/5a46d/migrate.png 300w", "/static/e55833300837409f3f56c3748c08069b/0a47e/migrate.png 600w", "/static/e55833300837409f3f56c3748c08069b/c1b63/migrate.png 1200w", "/static/e55833300837409f3f56c3748c08069b/d61c2/migrate.png 1800w", "/static/e55833300837409f3f56c3748c08069b/f349c/migrate.png 2002w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "updated-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#updated-interface",
        "aria-label": "updated interface permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Updated Interface`}</h2>
    <p>{`The open source `}<a parentName="p" {...{
        "href": "https://app.uniswap.org/"
      }}>{`interface`}</a>{` has been updated to work with Uniswap V2!`}</p>
    <p>{`It now supports creating and providing liquidity in ERC20 / ERC20 pairs, such as DAI/USDC.`}</p>
    <p>{`Swapping logic has been updated to properly route between ERC20 / ERC20 pairs.`}</p>
    <p>{`While Uniswap V2 uses WETH, this is abstracted in the interface and ETH can be used directly.`}</p>
    <h4 {...{
      "id": "swapping",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#swapping",
        "aria-label": "swapping permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Swapping`}</h4>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/346ad836ce955e49f05d5b3ba4751efa/da893/swap.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsSAAALEgHS3X78AAACGElEQVQ4y3WSy47TMBSG+yIwbZqktZ3YuTWpm1vblFmgETexZTkMSIx4DXa8FtIglvA4P8d2ehkxLH6lqf/zOec/Z6LrAZv2gLK7geg/QCQNoqwjbRHle9KAqBhl3/fgaY9sfcCLl++h22vo5oBKD6g2AyYy1VB5A1n0EFQs0pZgPWlHhdsReBhF5/nOXhbnW6TlHjLvqL6FTGvEicaExytwWZHWEEpboEg7hCyDytYIeYpgmdAzg79Q9L97Nx5O3XBVU622DBGXmAhZEohgyca2a4xGXiDAowTzgGM2X2LuL+HNQ/ihwJxkOzFeU2NqDUNeApUB1tbEEzLLAk27h663FjJbFsjX15RVhwVXzmOBtav9F6hHYGvNIVOIVU4qCBgRMEcoKgSLGJ7Pxmja/wBN/yPQmVrKSWJKrV55C8wIYCBMSPISmM5MftEJSLXySSDdlDbWzATl5y9sbub57MrHrlYoVwXlKqzHeF1+2jEskKbsgMcc3eTMVANewAsTTAOarljRetBq0HqYSXPlLj+3a6a8OgIvcjRtiBTezTdMv/zG89sfYPc/Ib7+wvTuAd79H3ivv4PHxQjUp/weA49faTKh32H5BuFwj3D3GcH2Dn7/EV57C6//hKB8d161i3bPwBP0PO1YVciyCimJ8QRLmjqjdWFMOv/lMCyjeApYPVohrpox/G7U5aq4L7Pnm7cQ61dg2YC//sCerIkQQT4AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "swap",
            "title": "swap",
            "src": "/static/346ad836ce955e49f05d5b3ba4751efa/c1b63/swap.png",
            "srcSet": ["/static/346ad836ce955e49f05d5b3ba4751efa/5a46d/swap.png 300w", "/static/346ad836ce955e49f05d5b3ba4751efa/0a47e/swap.png 600w", "/static/346ad836ce955e49f05d5b3ba4751efa/c1b63/swap.png 1200w", "/static/346ad836ce955e49f05d5b3ba4751efa/d61c2/swap.png 1800w", "/static/346ad836ce955e49f05d5b3ba4751efa/da893/swap.png 2130w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h4 {...{
      "id": "liquidity-provision",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#liquidity-provision",
        "aria-label": "liquidity provision permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Liquidity Provision`}</h4>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/e3798666ddcc556cbbece919d22f7900/5e703/pool.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "85%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAYAAADdRIy+AAAACXBIWXMAAAsSAAALEgHS3X78AAACdElEQVQ4y22Ty3LTQBBF8xmAg229PHqM3pItybYcP2JTVGUDLAgG8jvZsGRJKFikUsA/XnpGY1sqZ9GlkWb69L3dmgsvmsGL5xQ1eLKguAJPl+DZGn62gZ9fd4LTNxnpqjlLOSJXMoh1cQ5cwqODdjg7hhPO4UTzZk1PJxQ5qrAAJgoYC2CsgHJjISs7YQV9cAlmaNBev4LWv4SpDdDvvUDoMfo+wMhNycH6mHdQqYC12miqChXDm28YfvoH4/YR7tcneHd/wD4/Qd//xfDDA1i6Veq6tjtA3gKaN/ew9o/wvvxGdPcLfP8A9/YHwX5Cf/cdLLlWPXwWOFfAxrIdVNJaFnHkcQDbMsjuS7Lfg97vScs2z+XguMo7ByqVfrbCiFcoJgnyaoU4q2DZATTThW5xGYblgfGJLH7ew6gLbBSWcHiGIEzh+QkBCGY60AwbQ51B00ddYNyechtI4adC4QT5ZIntdkfP2REk4OOiRkh9tiVw2QU2/+EUbdviEPMLSo5RzZYSoil1zAlQTmvU04QU5g1QCekC2z93eiXthPEY680b1Is1TEsAGQaahTQvUZTUVzeTZ9swwVLAk0oxMYd62KiisDNobgHdK+Vap+EMhVovV9NtetcCVjiqpJBAf4IhSzDwV9CiHfruDH27hJG+hR7vMGA5ATMFPMEE68ILS7ShnCwIoFBnsIASE7pmERiF4aTQRpFSKIB1B+aFB2BYnaBU0aV3k2C2G8OPxuBhjiAiVVTItBOYI5+KFlR8jmPLBINYBCxwBj0MidZ2RC0o38OdfoSTblTReUvZCSZYCvgcdHpKpvsqQ0y0vadsNrkN8D926R3z/pavqwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "pool",
            "title": "pool",
            "src": "/static/e3798666ddcc556cbbece919d22f7900/c1b63/pool.png",
            "srcSet": ["/static/e3798666ddcc556cbbece919d22f7900/5a46d/pool.png 300w", "/static/e3798666ddcc556cbbece919d22f7900/0a47e/pool.png 600w", "/static/e3798666ddcc556cbbece919d22f7900/c1b63/pool.png 1200w", "/static/e3798666ddcc556cbbece919d22f7900/d61c2/pool.png 1800w", "/static/e3798666ddcc556cbbece919d22f7900/5e703/pool.png 1942w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h4 {...{
      "id": "pool-creation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#pool-creation",
        "aria-label": "pool creation permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Pool Creation`}</h4>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/1cdbd2679c49d777655d6f6cdf9d310a/c0566/create.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "69%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsSAAALEgHS3X78AAAB50lEQVQ4y4XUzW6bMAAH8LzGpKhgjI3BhiQkhDTNkpKPqYdJk7bLsmfYYdPUtNN27IvuttPWdg/wnzEGEiZlh78AG//8gU1PpSuo9LrKuNBZ22ygJmW2NpuqrKkv2nbakKOlSa8FK0zWDSwgbZoOjtG0OAPqF8JkjiAagokBaJCAh0OEamzCw5Ep84OBqRfJpUUtaNFePexY986jCSiPQWgIx2VQSYrFco351QpxMoZDGCiLIKIELEyrEXem3YLZBlRkKOYx8qyElYaFwc3VD+HplKDPI935+DyYZFt4YoLNYoDLWa6nFhvIs1AZcgSy/4FlpVAZXvQZ+g7XWNBAJVLjJep6/NwIV80asiiDUgKRGlZr6UfwmGzjl1dlOuiCsgsmeg1JMMXrZR/LWYyA63VzLuC5bhPiOqCEgPu081FWp6C02yZQU1yM3sCZ7kHy9/Bmezj5Hq4O0fflM5l9gDt5Cy7zo83d2YcGTJfgoy3czz9Avv6Bc/iN4Nsjpg/PJuH3Rzi3v0Dun+B++QmWv4MaXem2xSlobuy0xWABev0RdHcPur2FvzsgvLmDuDmAvTqYMrq7A11/ghiu2qk24MsKPD7PMs4g9cmQ8cQkUlXqZxlXdW2bFmvAE/Sfn0I39jx31q4G/wIGxsPlBtckLgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "create",
            "title": "create",
            "src": "/static/1cdbd2679c49d777655d6f6cdf9d310a/c1b63/create.png",
            "srcSet": ["/static/1cdbd2679c49d777655d6f6cdf9d310a/5a46d/create.png 300w", "/static/1cdbd2679c49d777655d6f6cdf9d310a/0a47e/create.png 600w", "/static/1cdbd2679c49d777655d6f6cdf9d310a/c1b63/create.png 1200w", "/static/1cdbd2679c49d777655d6f6cdf9d310a/c0566/create.png 1544w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "info-site",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#info-site",
        "aria-label": "info site permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Info Site`}</h2>
    <p>{`Uniswap V2 analytics are available at `}<a parentName="p" {...{
        "href": "http://uniswap.info/"
      }}>{`uniswap.info`}</a>{`, built on top of the open source `}<a parentName="p" {...{
        "href": "https://github.com/Uniswap/uniswap-v2-subgraph"
      }}>{`Uniswap V2 subgraph`}</a>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/83f8197632f23250f154bd8ea63ef37f/a09be/info.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "63.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEF/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAdu0oJ//xAAXEAADAQAAAAAAAAAAAAAAAAABECFB/9oACAEBAAEFAjAsX//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABQQAQAAAAAAAAAAAAAAAAAAACD/2gAIAQEABj8CX//EABgQAAMBAQAAAAAAAAAAAAAAAAABMREh/9oACAEBAAE/IaHBdQoYn0YI/9oADAMBAAIAAwAAABCQD//EABYRAQEBAAAAAAAAAAAAAAAAABEBEP/aAAgBAwEBPxBhn//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABoQAQEBAQEBAQAAAAAAAAAAAAERACFRMUH/2gAIAQEAAT8Qhng+8xacj05lQ/jnhLfdAPmAECByb//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "info",
            "title": "info",
            "src": "/static/83f8197632f23250f154bd8ea63ef37f/e5166/info.jpg",
            "srcSet": ["/static/83f8197632f23250f154bd8ea63ef37f/f93b5/info.jpg 300w", "/static/83f8197632f23250f154bd8ea63ef37f/b4294/info.jpg 600w", "/static/83f8197632f23250f154bd8ea63ef37f/e5166/info.jpg 1200w", "/static/83f8197632f23250f154bd8ea63ef37f/d9c39/info.jpg 1800w", "/static/83f8197632f23250f154bd8ea63ef37f/df51d/info.jpg 2400w", "/static/83f8197632f23250f154bd8ea63ef37f/a09be/info.jpg 2772w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "what-will-happen-to-uniswap-v1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#what-will-happen-to-uniswap-v1",
        "aria-label": "what will happen to uniswap v1 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What will happen to Uniswap V1`}</h2>
    <p>{`Uniswap V1 is an automated, decentralized set of smart contracts. It will continue functioning for as long as Ethereum exists.`}</p>
    <h2 {...{
      "id": "all-other-questions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#all-other-questions",
        "aria-label": "all other questions permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`All other questions`}</h2>
    <p>{`For all questions, you can join and engage with the community in the `}<a parentName="p" {...{
        "href": "https://discord.gg/FCfyBSbCU5"
      }}>{`Uniswap Discord`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      